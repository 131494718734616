import { useCallback, useMemo } from 'react'
import { Loader } from 'components'
import { OfficeHoursForm } from 'domains/customer/screens/OfficeHour/components'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { OfficeHoursPayload } from 'services/officeHours/types'

import { usePutOfficeHours } from 'shared/hooks/services/officeHours/usePutOfficeHours'

import { useGetOfficeHours, useToast } from 'shared/hooks'
import { groupIntoBusinessDays } from 'domains/customer/screens/OfficeHour/utilities/utilities'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { buildPath, paths } from 'routes'

const Update = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { officeHourId } = useParams()
  const { addToast } = useToast()
  const { account } = useCustomerContext()

  const { data: fetchedOfficeHours, isFetching } = useGetOfficeHours(
    officeHourId || '',
    !!officeHourId,
  )

  const officeHoursStateParams = location?.state?.officeHoursData

  const officeHoursData = useMemo(() => {
    if (officeHoursStateParams) return officeHoursStateParams

    if (fetchedOfficeHours) {
      const formattedScales = groupIntoBusinessDays(fetchedOfficeHours?.scales)

      return {
        ...fetchedOfficeHours,
        scales: formattedScales,
        parameters: {
          active: fetchedOfficeHours?.active,
          armedOutOfScaleActive: fetchedOfficeHours.armedOutOfScaleActive,
          disarmedOutOfScaleActive: fetchedOfficeHours.disarmedOutOfScaleActive,
          notArmedActive: fetchedOfficeHours.notArmedActive,
          notDisarmedActive: fetchedOfficeHours.notDisarmedActive,
        },
      }
    }
  }, [officeHoursStateParams, fetchedOfficeHours])

  const { mutate } = usePutOfficeHours()

  const onSubmit = useCallback(
    (id: string, formData: OfficeHoursPayload) => {
      mutate(
        { id, payload: formData },
        {
          onSuccess: () => {
            addToast({ message: 'Horário editado com sucesso.' })

            navigate(
              buildPath({
                path: paths.account.details + '?tab=officeHour&page=1',
                params: { accountId: account?.id },
              }),
            )
          },
          onError: () => {
            addToast({
              message: 'Erro ao editar horário. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [mutate, addToast, navigate],
  )

  if (!officeHoursData || isFetching) return <Loader isVisible />

  return (
    <OfficeHoursForm
      onSubmit={(formData) => onSubmit(officeHoursData.id, formData)}
      officeHours={officeHoursData}
    />
  )
}

export default Update
